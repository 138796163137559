<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t("Add New") : $t("Update") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <!-- name -->
              <b-form-group
                :label="$t('Name')"
                label-for="register-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="Name"
                  rules="required"
                >
                  <b-form-input
                    id="register-name"
                    v-model="name"
                    name="register-name"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <!-- detail -->
              <b-form-group
                :label="$t('Detail')"
                label-for="register-detail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Detail"
                  vid="detail"
                  rules="required"
                >
                  <b-form-input
                    id="register-detail"
                    v-model="detail"
                    name="register-detail"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Detail')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <!-- Agency -->
              <b-form-group
                :label="$t('Agency')"
                label-for="register-agency"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Agency"
                  vid="agency"
                  rules="required"
                >
                  <v-select
                    v-model="agencyId"
                    :options="agency"
                    :reduce="(agency) => agency._id"
                    multiple
                  >
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      {{ showFromCurrentLanguage(option.name) }}
                    </template>
                    <template
                      slot="selected-option"
                      slot-scope="option"
                    >
                      {{ showFromCurrentLanguage(option.name) }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- department -->
              <b-form-group
                :label="$t('Department')"
                label-for="register-department"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Department"
                  vid="department"
                  rules="required"
                >
                  <v-select
                    v-model="departmentId"
                    :options="department"
                    :reduce="(department) => department._id"
                    multiple
                  >
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      {{ showFromCurrentLanguage(option.name) }}
                    </template>
                    <template
                      slot="selected-option"
                      slot-scope="option"
                    >
                      {{ showFromCurrentLanguage(option.name) }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar,
  BButton,
  BFormInput, BForm, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
    agency: {
      type: Array,
      default: () => [],
    },
    department: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dataId: null,
      name: '',
      detail: '',
      agencyId: null,
      departmentId: null,
      required,
    }
  },
  computed: {
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            image, firstName, lastName, userId, tel,
          } = this.data
          this.dataId = userId._id
          this.image = image
          this.firstName = firstName
          this.lastName = lastName
          this.username = userId.username
          this.tel = tel
        }
      }
    },
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    initValues() {
      this.dataId = null
      this.name = ''
      this.detail = ''
      this.agencyId = null
      this.departmentId = null
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            name: this.name,
            detail: this.detail,
            agency: this.agencyId,
            department: this.departmentId,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
